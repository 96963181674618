import { Controller } from "react-hook-form";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { FilledInput, FilledInputProps, InputAdornment, Typography } from "@material-ui/core";
import React, { FC, Fragment, ReactNode, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { useFilledInputStyles } from "./styles";

interface ControlledDatePickerProps extends FilledInputProps {
    control: any;
    errors?: boolean;
    name: string;
    required?: boolean;
    label?: string;
    icon?: ReactNode;
    min?: any;
    max?: any;
    step?: string;
    needFormat?: boolean;
}

function formatDate(input) {
    console.log("input", input);
    if (input) {
        const datePart = input.match(/\d+/g);
        const year = datePart[0].substring(2);
        const month = datePart[1];
        const day = datePart[2];

        return day + "." + month + "." + year;
    }
    return "";
}

export const ControlledDatePicker: FC<ControlledDatePickerProps> = ({ control, errors, name, label, icon, required, ...props }) => {
    const classes = useFilledInputStyles();
    const [dateFocused, setDateFocused] = useState(false);

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                    rules={{
                        validate: props.min
                            ? date => {
                                  const firstDate = date.valueOf();
                                  const secondDate = props.min.valueOf();
                                  return secondDate <= firstDate || `Wert muss ${formatDate(props.min)} oder später sein`;
                              }
                            : undefined
                    }}
                    control={control}
                    name={name ?? "date"}
                    render={({ field, fieldState: { error } }) => (
                        <div style={{ position: "relative" }}>
                            <FilledInput
                                {...field}
                                value={!dateFocused && props.needFormat && field.value ? formatDate(field.value) : field.value}
                                placeholder={props.placeholder}
                                className={classes.filledInput}
                                required
                                type={dateFocused ? "date" : "text"}
                                error={!!error}
                                onFocus={() => setDateFocused(true)}
                                onBlur={() => setDateFocused(false)}
                                startAdornment={<InputAdornment position={"start"}>{icon}</InputAdornment>}
                                inputProps={{ min: props.min, max: props.max }}
                                disabled={props.disabled}
                            />
                            {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                        </div>
                    )}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export const ControlledYearPicker: FC<ControlledDatePickerProps> = ({ control, errors, name, label, icon, required, ...props }) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                    control={control}
                    name={name ?? "date"}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            autoOk
                            fullWidth
                            views={["year"]}
                            variant={"inline"}
                            inputVariant={"outlined"}
                            className={classes.filledInput}
                        />
                    )}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export const ControlledDateAndTimePicker: FC<ControlledDatePickerProps> = ({ control, errors, name, label, icon, required, ...props }) => {
    const classes = useFilledInputStyles();

    return (
        <Fragment>
            {label && <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{label}</Typography>}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                    rules={{
                        validate: props.min
                            ? date => {
                                  const firstDate = date.valueOf();
                                  const secondDate = props.min.valueOf();
                                  return (
                                      secondDate <= firstDate ||
                                      `Der Wert darf nicht vor dem ${new Date(props.min).toLocaleString("de-DE", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit"
                                      })} liegen`
                                  );
                              }
                            : undefined
                    }}
                    control={control}
                    name={name ?? "date"}
                    render={({ field, fieldState: { error } }) => (
                        <div style={{ position: "relative" }}>
                            <FilledInput
                                {...field}
                                type="datetime-local"
                                placeholder={props.placeholder}
                                className={classes.filledInput}
                                error={!!error}
                                required
                                inputProps={{ min: props.min, step: props.step }}
                            />
                            {error !== undefined && <Typography className={classes.errorMessage}>{error.message}</Typography>}
                        </div>
                    )}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};
