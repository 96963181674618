import { replace } from "lodash";
import numeral from "numeral";
require("numeral/locales/de");
import { format, formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";
import { Address } from "@onpreo/database/src";
import { regexps } from "@onpreo/components/src/regex";

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";
// ----------------------------------------------------------------------

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    key: "Hello"
                }
            },
            de: {
                translation: {
                    key: "Hallo"
                }
            }
        },
        fallbackLng: "de",
        detection: {
            order: ["navigator", "cookie", "htmlTag"],
            caches: ["cookie", "localStorage"]
        },
        interpolation: {
            escapeValue: false
        }
    });

export function getSeparators() {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, { useGrouping: true });
    const parts = formatter.formatToParts(123456);
    let thousandSeparator = "";
    let decimalSeparator = "";

    for (let part of parts) {
        if (part.type === "group") {
            thousandSeparator = part.value;
        } else if (part.type === "decimal") {
            decimalSeparator = part.value;
        }
    }

    // special case for Swiss local to use ' instead of ´
    if (locale === "de-CH") {
        thousandSeparator = "'";
    }

    // make sure to set a default thousand separator if none is found
    thousandSeparator = thousandSeparator || ".";
    decimalSeparator = decimalSeparator || ",";

    return { thousandSeparator, decimalSeparator };
}

export function currencyCodeToSymbol(currencyCode, locale = "en") {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    // formatting a symbolic number to extract the currency format
    const formatted = formatter.format(0);

    // delete numeric and extra characters to get only the currency symbol
    const symbol = formatted.replace(/[\d.,\s]/g, "").trim();

    return " " + symbol;
}

// include checker for currency Format
export function CurrencyShort(number) {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    let formattedNumber = formatter.format(number);

    if (locale === "de-CH") {
        const parts = formatter.formatToParts(123456);
        let incorrectSeparator = "";

        for (let part of parts) {
            if (part.type === "group") {
                incorrectSeparator = part.value;
                break;
            }
        }

        const correctSeparator = "'";
        formattedNumber = formattedNumber.replace(new RegExp(incorrectSeparator, "g"), correctSeparator);
    }

    return formattedNumber;
}

export function CurrencyDecimal(number) {
    return numeral(number).format(Number.isInteger(number) ? "0,0" : "0,0.00");
}

export function Currency(number) {
    return "€" + numeral(number).format(Number.isInteger(number) ? "0,0" : "0,0.00");
}

export function Percent(number) {
    return numeral(number / 100).format("0.0%");
}

export function formatNumber(number) {
    return numeral(number).format();
}

export function ShortenNumber(number) {
    return replace(numeral(number).format("0.00a"), ".00", "");
}

export function Data(number) {
    return numeral(number).format("0.0 b");
}

export function formatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd MMMM yyyy", { locale: de });
}

export function showFormatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd.MM.yyyy");
}

export function formatDateYY(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd.MM.yy");
}

export function saveFormatDate(value, separator) {
    const [day, month, year] = value.split(separator);
    const formatedDate = day && month && year ? new Date(+year, month - 1, +day) : value;

    return formatedDate;
}

export function DateTime(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function formatDateWithTime(date) {
    return format(new Date(date), "dd.MM.yyyy, HH:mm", { locale: de });
}

export function formatForDatePicker(date) {
    return format(new Date(date), "yyyy-MM-dd'T'HH:mm", { locale: de });
}

export function DateTimeSuffix(date) {
    return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function ToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function parseAddress(address: Address) {
    if (address)
        return typeof address === "string" ? address : `${address?.street} ${address?.house_number}, ${address?.zip} ${address?.town}, ${address?.nation}`;
    else return "keine Straße 0, 0 keine Stadt, kein Land";
}

export function parseLocaleNumber(stringNumber) {
    const { thousandSeparator, decimalSeparator } = getSeparators();
    const cleanNumber = stringNumber
        .replace(new RegExp(`\\${thousandSeparator}`, "g"), "") // delete thousand separator
        .replace(new RegExp(`\\${decimalSeparator}`, "g"), "."); // replace decimal separator with dot, to get a valid number

    return parseFloat(cleanNumber);
}

export function parseDecimalLocaleNumber(stringNumber) {
    const cleanNumber = stringNumber.replace(new RegExp(`\\,`, "g"), ".");
    return parseFloat(cleanNumber);
}

export function parseLocaleNumber2(stringNumber) {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale);
    const decimalSeparator = formatter.format(1.1).replace(/\p{Number}/gu, "");
    const cleanNumber = stringNumber.replace(new RegExp(`\\${decimalSeparator}`, "g"), ".");

    return parseFloat(cleanNumber);
}

export function toArrayBuffer(buf) {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
        view[i] = buf[i];
    }
    return ab;
}

export const arrayBufferToBase64 = buffer => {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export function convertDateFormat(dateToBeUpdated) {
    if (dateToBeUpdated && !dateToBeUpdated.match(/^\d/)) {
        const date = new Date(dateToBeUpdated);
        return date;
    } else return dateToBeUpdated;
}

export const checkValidDate = inputDate => {
    // Regular expression to check if the inputDate is in the correct format
    const regex = regexps.dateAndHours;
    // Check if the inputDate is in the correct format
    if (!regex.test(inputDate)) {
        return undefined;
    }

    return new Date(inputDate);
};

export function getProvisionWithoutTax(country: string, provision: number) {
    if (country) {
        switch (country) {
            case "Deutschland":
                return Math.round(provision / 1.19);
            case "Österreich":
                return Math.round(provision / 1.2);
            case "Schweiz":
                return Math.round(provision / 1.081);
        }
    }
}

export function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function getCountryCode(country: string) {
    switch (country) {
        case "Germany":
        case "Deutschland":
        case "DE":
            return "DE";
        case "Austria":
        case "Österreich":
        case "AT":
            return "AT";
        case "Switzerland":
        case "Schweiz":
        case "CH":
            return "CH";
        default:
            return country;
    }
}

export function getAddressParameterFromString(address: string, parameter: string) {
    try {
        if (address) {
            const split = address.split(",");
            switch (parameter) {
                case "street":
                    return split[0]?.split(" ")?.length > 1
                        ? split[0]?.split(" ").slice(0, -1).join(" ").trim()
                        : split[0]?.split(" ").slice().join(" ").trim();
                case "house_number":
                    return split[0]?.split(" ").slice(-1)?.length > 1 ? split[0]?.split(" ").slice(-1)[0].trim() : "2";
                case "town":
                    return split[1]?.trim();
                case "nation":
                    return getCountryCode(split[2].trim());
            }
        }
    } catch (err) {
        return undefined;
    }
}

export function hasGarage(answer: string) {
    switch (answer) {
        case "Ja":
            return true;
        case "Nein":
            return false;
        default:
            return;
    }
}

export const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export function parseContactGender(gender) {
    switch (gender) {
        case "male":
            return "Herr";
        case "female":
            return "Frau";
        default:
            return "Herr";
    }
}

// below 2 functions formulas are from excel sheets provided by tobi
export function getInterestValue(value: number, interestRate: number, repayment: number) {
    const interestValue = (value * (interestRate / 100) + value * (repayment / 100)) / 12;
    return Math.round(interestValue);
}

// calculation logic accoring to excel sheet provided by tobi "Calculating_Annuitätendarlehen-2.xlsx"
// calculate "Zinsbelastung in 10 Jahren" in this sheet to a fixed loanAmount
// the same function also exists in apps/price-assistant-app/src/utils/formatters.ts! Please make sure to keep both in sync
export function getInterestShare(loanAmount: number, interestRate: number, repayment: number): number {
    let interestRateSum = 0;
    let repaymentShare = loanAmount * (repayment / 100);
    let interestShare = loanAmount * (interestRate / 100);
    let residualDebt = loanAmount;
    const annuität = interestShare + repaymentShare;
    for (let i = 1; i <= 10; i++) {
        residualDebt -= repaymentShare;
        interestShare = residualDebt * (interestRate / 100);
        interestRateSum += interestShare;
        repaymentShare = annuität - interestShare;
    }
    return Math.round(interestRateSum);
}

export function getGridPosition(increase: number) {
    const defaultGridWidth = 110;
    const defaultTotalWidth = 765;
    return defaultTotalWidth - (defaultGridWidth * increase) / (100 * 2);
}

export function replaceSpaces(str: string) {
    return str.replace(/\s/g, "");
}

export function replaceUmlaut(str: string) {
    if (str) {
        let replacedString = str;
        for (var i = 0; i < replacedString.length; i++) {
            if (str.charCodeAt(i) === 776) {
                replacedString = replacedString.replace(str.charAt(i), "e");
            } else if (str.charCodeAt(i) === 223) {
                replacedString = replacedString.replace(str.charAt(i), "ss");
            } else if (str.charCodeAt(i) === 769) {
                replacedString = replacedString.replace(str.charAt(i), "e");
            }
        }
        return replacedString;
    }
}

export function getDateTimeFromString(date: any) {
    const dateObject = new Date(date);
    return dateObject.toISOString().slice(0, 16).replace("T", " ");
}

export const clearTableComponentStorage = () => {
    localStorage.removeItem("scroll");
    localStorage.removeItem("pageCount");
    localStorage.removeItem("rowPerPageCount");
    localStorage.removeItem("searchQuery");
};

export function isNotEmpty(str) {
    return str ? str.length !== 0 : false;
}

export function addMinutes(dateString, minutes) {
    const date = new Date(dateString);
    return new Date(date.getTime() + minutes * 60000);
}

// check for daylight saving
export const checkIsDST = (date: string) => {
    if (date) {
        const currentDate = new Date(date);
        const outsideDSTDate = new Date(currentDate.getFullYear(), 0, 1);
        const currentOffset = currentDate.getTimezoneOffset();
        const outsideDSTOffset = outsideDSTDate.getTimezoneOffset();
        return currentOffset < outsideDSTOffset;
    }
};

export function isOlderThan90Days(dateString): boolean {
    if (dateString) {
        const currentDate = new Date();
        const dateDifference = currentDate.getTime() - new Date(dateString).getTime();
        const daysDifference = dateDifference / (1000 * 60 * 60 * 24);
        return daysDifference > 90;
    } else return false;
}

export function variantToPathMap(variant: string): string {
    switch (variant) {
        case "lead":
            return "leads";
        case "prospectiveBuyer":
            return "prospective-buyers";
        case "tipper":
            return "tippers";
        default:
            return "contacts";
    }
}

export function returnStringIfNotEmpty(str) {
    return str?.length !== 0 ? str : undefined;
}

export function bringToTop(arr, element) {
    const index = arr.indexOf(element);
    if (index !== -1) {
        arr.splice(index, 1);
        arr.unshift(element);
    }
    return arr;
}

export const openInNewTab = (url: string) => {
    return window.open(url, "_blank");
};

export default {
    Currency,
    Percent,
    formatNumber,
    ShortenNumber,
    Data,
    formatDate,
    DateTime,
    DateTimeSuffix,
    ToNow,
    parseLocaleNumber,
    formatDateWithTime,
    toArrayBuffer,
    arrayBufferToBase64,
    convertDateFormat,
    getProvisionWithoutTax,
    dateDiffInDays,
    getCountryCode,
    getAddressParameterFromString,
    hasGarage,
    arrayRange,
    parseContactGender,
    getInterestValue,
    getInterestShare,
    getGridPosition,
    replaceSpaces,
    replaceUmlaut,
    getDateTimeFromString,
    clearTableComponentStorage,
    isNotEmpty,
    addMinutes,
    checkIsDST,
    isOlderThan90Days,
    variantToPathMap,
    returnStringIfNotEmpty,
    i18n,
    bringToTop,
    openInNewTab
};
